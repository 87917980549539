import React, { useState } from "react";

import confetti from "canvas-confetti";
import { useForm, UseFormGetValues } from "react-hook-form";

import { lockedScroll } from "../misc/util";
import { FormControl } from "../ui/form-control";
import { FormSelect } from "../ui/form-select";

interface Props {
  children: (options: {
    form: JSX.Element;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    getValues: UseFormGetValues<FormData>;
  }) => JSX.Element;
  geolocation: {
    ipCountry: string;
    ipCity: string;
  };
}

type FormData = {
  name: string;
  company: string;
  phone: string;
  email: string;
  budget: string;
  message: string;
};

const budgetOptions: { [key: string]: string[] } = {
  AR: [
    "$350.000 - $749.999",
    "$750.000 - $1.499.999",
    "$1.500.000 - $2.999.999",
    "$3.000.000 - $5.999.999",
    "$6.000.000+",
  ],
  CL: ["15 UF - 29 UF", "30 UF - 59 UF", "60 UF - 119 UF", "120 UF - 249 UF", "250+ UF"],
  CO: [
    "$2.000.000 - $4.999.999",
    "$5.000.000 - $9.999.999",
    "$10.000.000 - $19.999.999",
    "$20.000.000 - $39.999.999",
    "$40.000.000+",
  ],
  MX: [
    "$10,000 - $19,999",
    "$20,000 - $39,999",
    "$40,000 - $79,999",
    "$80,000 - $159,000",
    "$160,000+",
  ],
  ES: ["€ 500 - € 999", "€ 1,000 - € 2,499", "€ 2,500 - € 4,999", "€ 5,000 - € 9,999", "€ 10,000+"],
  US: ["$500 - $999", "$1,000 - $2,499", "$2,500 - $4,999", "$5,000 - $9,999", "$10,000+"],
  UY: [
    "$20.000 - $49.999",
    "$50.000 - $99.999",
    "$100.000 - $199.999",
    "$200.000 - $399.999",
    "$400.000+",
  ],
  default: [
    "USD 500 - USD 999",
    "USD 1,000 - USD 2,499",
    "USD 2,500 - USD 4,999",
    "USD 5,000 - USD 9,999",
    "USD 10,000+",
  ],
};

export const EstimateForm = ({ children, geolocation }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const { register, watch, handleSubmit, formState, getValues } = useForm<FormData>();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    setIsError(false);

    fetch("/api/send-email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        location: `${geolocation.ipCountry} / ${geolocation.ipCity}`,
      }),
    })
      .then((res) => (res.ok ? res.json() : Promise.reject(res)))
      .then(() => {
        setIsSuccess(true);
        lockedScroll();
        confetti({
          origin: { y: 0.75 },
          particleCount: 120,
          spread: 120,
          colors: ["#ffffff", "#3F6FE3", "#F26322"],
        });
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const isReadOnly = isLoading || isSuccess;

  return children({
    isLoading,
    isError,
    isSuccess,
    onSubmit,
    getValues,
    form: (
      <>
        <FormControl errors={formState.errors?.name}>
          <input
            placeholder="Nombre"
            readOnly={isReadOnly}
            type="text"
            {...register("name", {
              required: "El nombre es requerido",
              pattern: {
                value: /^[a-zA-Z ]+$/i,
                message: "Solo se permiten letras",
              },
              minLength: { value: 3, message: "Debe tener más de 3 caracteres" },
              maxLength: { value: 99, message: "Debe tener menos de 99 caracteres" },
            })}
          />
        </FormControl>

        <FormControl errors={formState.errors?.company}>
          <input
            placeholder="Empresa"
            readOnly={isReadOnly}
            type="text"
            {...register("company", {
              required: "La empresa es requerida",
              minLength: { value: 3, message: "Debe tener más de 3 caracteres" },
              maxLength: { value: 99, message: "Debe tener menos de 99 caracteres" },
            })}
          />
        </FormControl>

        <FormControl errors={formState.errors?.phone}>
          <input
            placeholder="Teléfono"
            readOnly={isReadOnly}
            type="text"
            {...register("phone", {
              required: "El teléfono es requerido",
              pattern: {
                value: /^[0-9\-]+$/i,
                message: "Ingresa un teléfono valido (solo números)",
              },
              minLength: { value: 7, message: "Debe tener más de 6 números" },
              maxLength: { value: 99, message: "Debe tener menos de 99 números" },
            })}
          />
        </FormControl>

        <FormControl errors={formState.errors?.email}>
          <input
            placeholder="Email"
            readOnly={isReadOnly}
            type="text"
            {...register("email", {
              required: "El email es requerida",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                message: "Ingresa un email valido",
              },
              minLength: { value: 3, message: "Debe tener más de 3 caracteres" },
              maxLength: { value: 99, message: "Debe tener menos de 99 caracteres" },
            })}
          />
        </FormControl>

        <FormControl errors={formState.errors?.budget}>
          <FormSelect
            defaultValue={{ text: "Presupuesto", value: "" }}
            formRegister={register("budget", { required: "El presupuesto es requerido" })}
            isDefaultValue={!watch("budget")}
            isReadOnly={isReadOnly}
          >
            {(budgetOptions[geolocation.ipCountry] ?? budgetOptions["default"]).map((value, i) => (
              <option key={`budget-${i}`} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormControl>

        <FormControl errors={formState.errors?.message}>
          <textarea
            placeholder="¿En que podemos ayudarte?"
            readOnly={isReadOnly}
            {...register("message", {
              required: "El mensaje es requerido",
              minLength: { value: 10, message: "Debe tener más de 10 caracteres" },
              maxLength: { value: 2000, message: "Debe tener menos de 2000 caracteres" },
            })}
          />
        </FormControl>
      </>
    ),
  });
};
