import React from "react";

import cn from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";

import ChevronDown from "../icons/ChevronDown";
import styles from "./form-select.css";

interface Props {
  formRegister: UseFormRegisterReturn<any>;
  defaultValue?: {
    text: string;
    value: string;
  };
  isReadOnly: boolean;
  isDefaultValue: boolean;
  children: React.ReactNode;
}

export const FormSelect = ({
  formRegister,
  defaultValue,
  isReadOnly,
  isDefaultValue,
  children,
}: Props) => {
  return (
    <div className={cn(styles.wrap, isReadOnly && styles.readOnly)}>
      <select
        className={cn(isDefaultValue && styles.selectDefault, styles.select)}
        defaultValue={defaultValue?.value}
        {...formRegister}
      >
        {defaultValue && (
          <option disabled value={defaultValue?.value}>
            {defaultValue.text}
          </option>
        )}
        {children}
      </select>

      <ChevronDown className={styles.arrow} />
    </div>
  );
};
