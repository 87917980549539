import React from "react";

import cn from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import { Kinesis } from "../../ui/kinesis";
import styles from "./image-contact.css";

export const ImageContact = () => {
  const { page } = useStaticQuery(graphql`
    query imageContact {
      page: sanityPageContact {
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 1160)
          }
        }
      }
    }
  `);

  return (
    <div className={styles.imageContainer}>
      <Kinesis>
        <GatsbyImage alt="Contacto" image={page.image.asset.gatsbyImageData} />
      </Kinesis>

      <Kinesis className={styles.image} speed={20}>
        <StaticImage alt="Avión de papel" src="../../images/contact/avion1.png" />
      </Kinesis>

      <Kinesis className={styles.image} speed={20}>
        <StaticImage alt="Avión de papel" src="../../images/contact/avion2.png" />{" "}
      </Kinesis>

      <Kinesis className={styles.image} speed={20}>
        <StaticImage alt="Avión de papel" src="../../images/contact/avion3.png" />{" "}
      </Kinesis>

      <Kinesis className={styles.image} speed={20}>
        <StaticImage alt="Avión de papel" src="../../images/contact/avion4.png" />{" "}
      </Kinesis>
    </div>
  );
};
