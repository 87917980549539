import React from "react";

import cn from "clsx";
import { FieldError } from "react-hook-form";

import styles from "./form-control.css";

interface Props {
  children: React.ReactNode;
  errors: FieldError | undefined;
}

export const FormControl = ({ children, errors }: Props) => {
  return (
    <div className={cn(styles.wrap, errors && "error")}>
      {children}
      <span className={styles.error}>{errors?.message}</span>
    </div>
  );
};
