import React, { useEffect, useState } from "react";

import { PortableText } from "@portabletext/react";
import cn from "clsx";
import { graphql, PageProps } from "gatsby";

import { Container } from "../components/container";
import { ImageContact } from "../components/image-contact";
import { Seo } from "../components/seo";
import { Title } from "../components/title";
import { EstimateForm } from "../forms/estimate-form";
import { capitalizeFirstLetterEachWord } from "../misc/string";
import { unlockedScroll } from "../misc/util";
import styles from "../styles/presupuesto.css";
import { ButtonClickSpecial } from "../ui/button-special";

const initialGeolocation = {
  ipCountry: "AR",
  ipCity: "Buenos Aires",
};

export default function PresupuestoPage({ data: { page } }: PageProps<any>) {
  const [geolocation, setGeolocation] = useState(initialGeolocation);

  const [enableOverlay, setEnableOverlay] = useState(true);

  useEffect(() => {
    fetch("/api/geolocation")
      .then((res) => res.json())
      .then((data) => {
        setGeolocation(data);
      })
      .catch(() => {
        setGeolocation(initialGeolocation);
      });
  }, []);

  const closeOverlay = () => {
    unlockedScroll(300);
    setEnableOverlay(false);
  };

  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />
      <Container className={styles.container} variant="solid">
        <div>
          <Title centerOnDesktop={false} subtitle={page.paragraph} text={page.title} />

          <EstimateForm geolocation={geolocation}>
            {({ form, isLoading, isError, isSuccess, onSubmit, getValues }) => (
              <div className={cn(isSuccess && enableOverlay && styles.successed)}>
                <div className={styles.wrap}>
                  <form className={styles.form} onSubmit={onSubmit}>
                    {form}

                    <div>
                      {isError ? (
                        <PortableText
                          components={{
                            block: {
                              normal: ({ children }) => (
                                <p className={styles.errorMessage}>{children}</p>
                              ),
                            },
                          }}
                          value={page._rawErrorMessage}
                        />
                      ) : null}

                      <ButtonClickSpecial
                        className={cn(
                          isSuccess && styles.buttonSuccess,
                          isError && styles.buttonError,
                        )}
                        isDisabled={isLoading || isSuccess}
                        isLoading={isLoading}
                        overlay={isSuccess ? "¡Mensaje enviado!" : undefined}
                        text={page.button}
                        type="submit"
                      />
                    </div>
                  </form>
                </div>

                <div className={styles.thanks}>
                  <div className={styles.thanksTitle}>
                    ¡Gracias {capitalizeFirstLetterEachWord(getValues("name") || "")}!
                  </div>
                  <div className={styles.thanksDescription}>
                    Te estaremos contactando muy pronto.
                  </div>
                  <ButtonClickSpecial
                    className={styles.thanksButton}
                    text="Seguir navegando"
                    onClick={closeOverlay}
                  />
                </div>
              </div>
            )}
          </EstimateForm>
        </div>

        <div className={styles.image}>
          <ImageContact />
        </div>
      </Container>
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageContact {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      title
      paragraph
      button
      _rawErrorMessage
    }
  }
`;
