import React, { useEffect, useRef } from "react";

import gsap from "gsap";

import useIsTouchDevice from "../hooks/use-is-touch-device";
import useWindowSize from "../hooks/use-window-size";

interface Props {
  children: React.ReactNode;
  className?: string;
  speed?: number;
}

export function Kinesis({ children, className = "", speed = 10 }: Props) {
  const { width, height } = useWindowSize();
  const isTouchDevice = useIsTouchDevice();

  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onMouseMove = ({ clientX, clientY }: MouseEvent) => {
      if (isTouchDevice) return;

      const x = (clientX / width - 0.5) * 2 * speed;
      const y = (clientY / height - 0.5) * 2 * speed;

      gsap.to(childRef.current, {
        x,
        y,
        duration: 1,
        ease: "expo.out",
      });
    };

    window.addEventListener("mouseover", onMouseMove, false);
    window.addEventListener("mousemove", onMouseMove, false);

    return () => {
      window.removeEventListener("mouseover", onMouseMove, false);
      window.removeEventListener("mousemove", onMouseMove, false);
    };
  }, [height, isTouchDevice, speed, width]);

  return (
    <div className={className}>
      <div ref={childRef}>{children}</div>
    </div>
  );
}
